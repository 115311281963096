<template>
    <div>
        <v-overlay :value="loading" :opacity="0.55" color="#d3d3d3">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title class="text-center justify-center py-6 custom-card-title">{{ title }}</v-card-title>
                    <v-card-text>
                        <v-tabs v-model="tab" centered icons-and-text ref="tabsItems">
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab v-if="!hideRoundRobin">
                                Round Robin
                                <v-icon>mdi-table-large</v-icon>
                            </v-tab>

                            <v-tab>
                                {{ knockoutGames.length == 1 ? 'Final' : 'Tournament' }}
                                <v-icon>mdi-tournament</v-icon>
                            </v-tab>

                        </v-tabs>

                        <v-tabs-items v-model="tab" touchless>
                            <v-tab-item :key="0" v-if="!hideRoundRobin">
                                <div>
                                    <v-row>
                                        <v-col>
                                            <v-data-table :headers="activeHeaders" :sort-by="['position']"
                                                :items="getCurrentStandings" hide-default-footer class="elevation-1"
                                                mobile-breakpoint="0" :custom-sort="customSort">

                                                <template v-slot:item.position="{ item }">
                                                    <h6 class="ma-0 font-weight-medium">
                                                        {{ item.position == 0 ? '-' : item.position }}
                                                    </h6>
                                                </template>

                                                <template v-slot:item.points="{ item }">
                                                    <h6 class="ma-0 font-weight-medium">
                                                        {{ item.points }}
                                                    </h6>
                                                </template>

                                                <template v-slot:item.pointsDifferenceFor="{ item }">
                                                    <h6 class="ma-0">
                                                        {{ item.pointsDifferenceFor ? item.pointsDifferenceFor : '-' }}
                                                    </h6>
                                                </template>

                                                <template v-slot:item.pointsDifferenceAgainst="{ item }">
                                                    <h6 class="ma-0">
                                                        {{ item.pointsDifferenceAgainst ? item.pointsDifferenceAgainst :
                                                            '-' }}
                                                    </h6>
                                                </template>

                                                <template v-slot:item.pointsDiff="{ item }">
                                                    <h6 class="ma-0">
                                                        {{ item.pointsDifferenceFor - item.pointsDifferenceAgainst }}
                                                    </h6>
                                                </template>

                                                <template v-slot:item.teamEnt.name="{ item }">
                                                    <div style="display: flex;" class="cursor-pointer"
                                                        @click="goToProfile(item.teamEnt.id)">
                                                        <div>
                                                            <v-img
                                                                :src="`/assets/gaa/teams/icons/${item.teamEnt.code}.WEBP`"
                                                                contain max-width="30" class="mr-2 img_border"></v-img>
                                                        </div>
                                                        <div>{{ item.teamEnt.name }}</div>
                                                    </div>
                                                </template>

                                            </v-data-table>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-tab-item>
                            <v-tab-item :key="2">
                                <v-container>
                                    <base-card>
                                        <v-card-text v-if="knockoutGames.length == 1"
                                            :class="$vuetify.breakpoint.xsOnly ? 'pa-1' : ''">
                                            <v-data-table :headers="knockoutHeaders" :items="knockoutGames"
                                                hide-default-footer hide-default-header class="elevation-1"
                                                mobile-breakpoint="0" :loading="loading">

                                                <template v-slot:item.homeLogo="{ item }">
                                                    <v-img :src="`/assets/gaa/teams/icons/${item.homeLogo}.WEBP`"
                                                        :alt="item.homeLogo" contain max-width="30"
                                                        class="img_border"></v-img>
                                                </template>
                                                <template v-slot:item.home="{ item }">
                                                    <h6 class="ma-0 font-weight-medium">
                                                        {{ item.homeTeam }}
                                                    </h6>
                                                </template>

                                                <template v-slot:item.date="{ item }">
                                                    {{ $vuetify.breakpoint.xsOnly ? item.matchResult ? item.homePoints +
                                                        ':' + item.awayPoints : formatDateUS(item.date) :
                                                        formatDateUS(item.date) }}
                                                </template>

                                                <template v-slot:item.time="{ item }">
                                                    {{ item.matchResult ? item.homeGoals + ':' + item.homePoints + ' - '
                                                        + item.awayGoals + ':' + item.awayPoints : item.time }}
                                                </template>

                                                <template v-slot:item.venue="{ item }">
                                                    {{ $vuetify.breakpoint.xsOnly ?
                                                        item.venue.substring(item.venue.indexOf(',') + 1) : item.venue }}
                                                </template>

                                                <template v-slot:item.away="{ item }">
                                                    <h6 class="ma-0 font-weight-medium">
                                                        {{ item.awayTeam }}
                                                    </h6>
                                                </template>
                                                <template v-slot:item.awayLogo="{ item }">
                                                    <v-img :src="`/assets/gaa/teams/icons/${item.awayLogo}.WEBP`"
                                                        :alt="item.awayLogo" contain max-width="30"
                                                        class="img_border"></v-img>
                                                </template>
                                            </v-data-table>
                                        </v-card-text>
                                        <div class="d-flex justify-center">
                                            <div ref="bracketContainer" id="bracket-container"
                                                style="overflow-x: scroll;"></div>
                                        </div>
                                    </base-card>
                                </v-container>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex';
import { createBracket } from 'bracketry';

export default {
    props: ['stage'],
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Standings',
    },
    data() {
        return {
            tab: 0,
            loading: true,
            hideRoundRobin: false,
            bracketInstance: null,
            roundRobinHeaders: [
                { text: 'Position', value: 'position', sortable: true },
                { text: 'Team', value: 'teamEnt.name', sortable: true },
                { text: 'Win', value: 'win', sortable: true },
                { text: 'Loss', value: 'loss', sortable: true },
                { text: 'Draw', value: 'draw', sortable: true },
                { text: 'For', value: 'pointsDifferenceFor', sortable: true },
                { text: 'Against', value: 'pointsDifferenceAgainst', sortable: true },
                { text: '+/-', value: 'pointsDiff', sortable: true },
                { text: 'Points', value: 'points', sortable: true },
            ],
            knockoutHeaders: [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'homeLogo',
                },
                { text: 'Home', value: 'home' },
                { text: '', value: 'time' },

                { text: 'Away', value: 'away' },
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'awayLogo',
                },
                { text: 'Date', value: 'date' },
            ],
            teams: [],
        }
    },
    computed: {
        ...mapGetters(["loggedInUser", "getFixtures", "getCurrentStandings", "getCurrentGameweek"]),
        tournamentData() {
            return {
                rounds: this.knockoutRounds,
                matches: this.knockoutGamesFormatted,
                contestants: this.knockoutGamesTeamsFormatted
            }
        },
        title() {
            let tournment = this.stage.charAt(0).toUpperCase() + this.stage.slice(1);
            if (tournment == 'Munster' || tournment == 'Leinster' || tournment == 'Ulster' || tournment == 'Connacht' || tournment == 'All Ireland') {
                let tournament = this.$router.currentRoute.meta.sport.charAt(0).toUpperCase() + this.$router.currentRoute.meta.sport.slice(1);
                return tournment + ` Senior ${tournament} Championship`;
            } else {
                return tournment + " Cup";
            }
        },
        competitionRounds() {
            let competitionRounds = [...this.getFixtures.reduce((set, fixture) => set.add(fixture.round), new Set())];
            return competitionRounds.filter(it => (it !== "Round Robin" && it != null));
        },
        // hideRoundRobin() {
        //     return !(this.getCurrentStandings && this.getCurrentStandings.length > 0)
        // },
        knockoutGames() {
            return this.getFixtures.filter(it => it.stage.toUpperCase() == this.stage.toUpperCase() && it.matchdayLabel.indexOf("Round Robin ") == -1);
        },
        knockoutGamesFormatted() {
            let games = this.knockoutGames.sort(it => it.matchday - it.matchday);
            let currentRound = 0;
            let currentRoundName = null;
            let roundOrder = 0;
            let numberOfGamesPerRound;
            let formattedGames = [];
            let numberOfRounds = this.knockoutRounds.length;
            if (numberOfRounds == 4) {
                numberOfGamesPerRound = 8;
            } else if (numberOfRounds == 3) {
                numberOfGamesPerRound = 4;
            } else if (numberOfRounds == 2) {
                numberOfGamesPerRound = 2;
            }
            for (let i = 0; i < games.length; i++) {
                if (!currentRoundName) {
                    currentRoundName = games[i].matchdayLabel;
                } else if (currentRoundName != games[i].matchdayLabel) {
                    currentRound++;
                    currentRoundName = games[i].matchdayLabel;
                    roundOrder = 0;
                    numberOfGamesPerRound = numberOfGamesPerRound / 2;
                }

                let modifiedRoundOrder = roundOrder;
                if (numberOfGamesPerRound == 8 && (roundOrder % 2 == 1)) {
                    modifiedRoundOrder = 8 - roundOrder;
                } else if (numberOfGamesPerRound == 4 && (roundOrder % 2 == 1)) {
                    modifiedRoundOrder = 4 - roundOrder;
                }

                formattedGames.push({
                    "roundIndex": currentRound,
                    order: modifiedRoundOrder,
                    sides: [
                        {
                            contestantId: String(games[i].homeTeamId),
                            currentScore: ""
                        },
                        {
                            contestantId: String(games[i].awayTeamId),
                            currentScore: ""
                        }
                    ]
                });
                roundOrder++;
            }
            return formattedGames;
        },
        knockoutGamesTeamsFormatted() {
            const teamMap = this.knockoutGames.reduce((acc, match) => {
                // Add home team
                if (match.homeTeamId && !acc[match.homeTeamId]) {
                    acc[match.homeTeamId] = {
                        players: [
                            {
                                title: match.homeTeam,
                                nationality: match.homeLogo,
                            },
                        ],
                    };
                }

                // Add away team
                if (match.awayTeamId && !acc[match.awayTeamId]) {
                    acc[match.awayTeamId] = {
                        players: [
                            {
                                title: match.awayTeam,
                                nationality: match.awayLogo,
                            },
                        ],
                    };
                }

                return acc;
            }, {});
            return teamMap;
        },
        knockoutRounds() {
            return Array.from(
                new Set(this.knockoutGames.sort((a, b) => a.matchday - b.matchday).map(it => it.matchdayLabel))
            ).map(name => ({ name }));
        },
        final() {
            let fixtures = [...this.getFixtures];
            if (this.stage.toLowerCase() == 'munster') {
                return fixtures.find(it => it.matchdayLabel == 'Munster Final');
            } else {
                return fixtures.find(it => it.matchdayLabel == 'Leinster Final');
            }
        },
        activeHeaders() {
            if (this.$vuetify.breakpoint.xsOnly) {
                return [
                    { text: 'Team', value: 'teamEnt.name', sortable: true },
                    { text: 'Points', value: 'points', sortable: true },
                    { text: '+/-', value: 'pointsDiff', sortable: true },
                    { text: 'Win', value: 'win', sortable: true },
                    { text: 'Loss', value: 'loss', sortable: true },
                    { text: 'Draw', value: 'draw', sortable: true },
                ]
            } else {
                return this.roundRobinHeaders;
            }
        }
    },
    methods: {
        ...mapActions(["fetchFixtures", "fetchCompetitionStandings", "fetchCompetitionStandingsForWeek"]),
        goToProfile(id) {
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/profile/${id}`)
        },
        formatDate(date) {
            return date.split('-').reverse().map((part, index) => index === 2 ? part.slice(-2) : part).join('/');
        },
        formatDateUS(date) {
            return date.replace(/(\d+)\/(\d+)\/(\d{2})(\d{2})/, (_, m, d, __, y) => `${d.padStart(2, '0')}/${m.padStart(2, '0')}/${y}`);
        },
        buildBracket() {
            if (!this.hideRoundRobin) return;
            const options = {
                getNationalityHTML: (team) => {
                    if (!team) return "BYE";
                    return `<div class="team">
                  <span class="img_border"><img style="max-width: 30px" src="/assets/gaa/teams/icons/${team.nationality}.WEBP"/</img></span>
                </div>`;
                },
                matchTextColor: this.$vuetify.theme.dark ? '#fff' : '#000',
                roundTitleColor: this.$vuetify.theme.dark ? '#fff' : '#000',
                navButtonsPosition: this.$vuetify.breakpoint.xsOnly ? 'beforeTitles' : 'overTitles',
                visibleRoundsCount: this.$vuetify.breakpoint.xsOnly ? 1 : 0
            };
            this.$nextTick(() => {
                this.bracketInstance = createBracket(
                    this.tournamentData,
                    this.$refs.bracketContainer,
                    options
                );
            })
        },
        customSort(items, sortBy, sortDesc) {
            items.sort((a, b) => {

                // A) Compare points: higher points come first
                if (a.points !== b.points) {
                    let sortvalue = sortDesc[0] ? a.points - b.points : b.points - a.points;
                    return sortvalue;
                }

                // Find all items with the same points as the ones being compared
                const samePointsItems = items.filter(item => item.points === a.points);

                // Only perform head-to-head comparison if there are exactly 2 items with the same points as a and b
                if (samePointsItems.length === 2 && samePointsItems.includes(a) && samePointsItems.includes(b)) {
                    // B) If points are the same, compare head-to-head record
                    const headToHeadComparison = this.compareHeadToHead(a, b);
                    if (headToHeadComparison !== 0) {
                        return headToHeadComparison;
                    }
                }

                // C) If points and head-to-head are the same, compare score difference
                return sortDesc[0]
                    ? Math.sign((a.pointsDifferenceFor - a.pointsDifferenceAgainst) - (b.pointsDifferenceFor - b.pointsDifferenceAgainst))
                    : Math.sign((b.pointsDifferenceFor - b.pointsDifferenceAgainst) - (a.pointsDifferenceFor - a.pointsDifferenceAgainst));

            });

            items.forEach((item, index) => (item.position = index + 1));
            if (this.stage.toUpperCase() == 'LEINSTER') {
                if (items[5] && (this.exclude != items[5].teamEnt.name)) {
                    this.exclude = items[5].teamEnt.name;
                }
            } else {
                items.forEach((item) => (item.exclude = false));
                this.exclude = null;
            }

            return items;
        },
        compareHeadToHead(teamA, teamB) {
            let fixture = this.getFixtures.find(it => (it.homeTeam == teamA.teamEnt.name && it.awayTeam == teamB.teamEnt.name) || it.homeTeam == teamB.teamEnt.name && it.awayTeam == teamA.teamEnt.name);
            let fixtureResult = null;
            if (fixture && fixture.matchResult == null) {

            } else {
                if (fixture) {
                    fixtureResult = fixture.matchResult;
                }
            }
            if (fixtureResult == "HOME_WIN" || fixtureResult == 1) {
                return fixture.homeTeam == teamA.teamEnt.name ? -1 : 1;
            } else if (fixtureResult == "AWAY_WIN" || fixtureResult == 2) {
                return fixture.homeTeam == teamA.teamEnt.name ? 1 : -1;
            } else {
                return 0;
            }
        }
    },
    async mounted() {
        await this.fetchFixtures(this.$store.getters.getCompetitionId);
        await this.fetchCompetitionStandingsForWeek({ "competitionId": this.$store.getters.getCompetitionId, "name": this.stage.toUpperCase(), "gameweek": this.getCurrentGameweek - 1 });
        this.loading = false;
    },
    watch: {
        knockoutGames() {
            const observer = new MutationObserver(() => {
                if (this.$refs.bracketContainer) {
                    observer.disconnect(); // Stop observing once the ref is ready
                    this.buildBracket();
                }
            });

            // Start observing changes in the DOM
            observer.observe(this.$el, { childList: true, subtree: true });
        },
        getCurrentStandings() {
            this.hideRoundRobin = !(this.getCurrentStandings && this.getCurrentStandings.length > 0);
        }
    }
}
</script>

<style lang="scss" scoped>
.current-score {
    border: none;
    /* Remove the box */
    background: transparent;
    /* Ensure no background */
    padding: 0;
    /* Remove any padding */
    font-size: 1.2em;
    /* Adjust size */
    text-align: center;
}

::v-deep .v-data-table-header th .v-icon {
    display: none;
}

/* Ensure the headers still look clickable */
.v-data-table-header th {
    cursor: pointer;
}

.img_border {
    border-style: solid;
    border-width: 2px;
}

.custom-card-title {
    word-break: keep-all !important;
    white-space: normal !important;
}
</style>